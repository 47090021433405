import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import './Carousel.css';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { useLocation } from 'react-router-dom'; // Importar useLocation



//categories: Es una variable de estado que inicialmente es un array vacío ([]). Guardará las categorías que obtienes de la base de datos.
//setCategories: Es la función que React proporciona automáticamente para cambiar el valor de categories.
//useState([]): Esta es una llamada a la función useState, que es un hook de React. 
//El valor entre paréntesis ([]) es el valor inicial del estado. En este caso, el estado inicial es un array vacío.
//selectedCategory: Es una variable de estado que comienza con el valor null. Se usará para almacenar la categoría que el usuario selecciona al hacer clic en los botones.
//setSelectedCategory: Esta función es proporcionada automáticamente por React y permite actualizar el valor de selectedCategory.
//useState(null): Aquí el valor inicial del estado es null, lo que significa que al inicio no hay ninguna categoría seleccionada.
//setImages: La función para actualizar el estado de images, proporcionada automáticamente por React.
const ImageCarousel = () => {

  const location = useLocation(); // Hook para obtener el query parameter
  const queryParams = new URLSearchParams(location.search); // Crear un objeto para leer los query parameters
  const universityId = queryParams.get('university');

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [images, setImages] = useState([]);
  const [benefits, setBenefits] = useState([]);

  const navigate = useNavigate();//inicializa useNavigate

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        /*const { data: university, error: universityError } = await supabase
          .from("university")
          .select("university_id")
          .eq("name", univerityName)
          .single();//Return data as a single object instead of an array of objects.

        if (universityError) throw universityError;

        const universityId = university.university_id;*/
        
        const { data: universityBenefits, error: universityBenefitsError } = await supabase
          .from("universitybenefit")
          .select("benefit_id")
          .eq("university_id", universityId);

        if (universityBenefitsError) throw universityBenefitsError;

        const benefitIds = universityBenefits.map(b => b.benefit_id);

        const { data: categories, error: categoriesError } = await supabase
          .from("benefit")
          .select("category_id")
          .in("id", benefitIds);

        if (categoriesError) throw categoriesError;

        const categoryIds = categories.map(c => c.category_id);

        const { data: categoryNames, error: categoryNamesError } = await supabase
          .from("category")
          .select("name")
          .in("id", categoryIds);

        if (categoryNamesError) throw categoryNamesError;

        setCategories(categoryNames.map(item => item.name));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [universityId]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        /*const { data: university, error: universityError } = await supabase
          .from("university")
          .select("university_id")
          .eq("name", "Instituto Tecnológico de Buenos Aires")
          .single();

        if (universityError) throw universityError;

        const universityId = university.university_id;*/

        const { data: featuredBenefit, error: featuredBenefitError } = await supabase
          .from("featured_benefits")
          .select("benefit_id")
          .eq("university_id", universityId);

        if (featuredBenefitError) throw featuredBenefitError;

        const featuredBenefitIds = featuredBenefit.map(b => b.benefit_id);

        const { data: imageBenefits, error: imageBenefitsError } = await supabase
          .from("imagebenefit")
          .select("image_id")
          .in("benefit_id", featuredBenefitIds);

        if (imageBenefitsError) throw imageBenefitsError;

        const imageIds = imageBenefits.map(b => b.image_id);

        const { data: images, error: imagesError } = await supabase
          .from("image")
          .select("base64image")
          .in("id", imageIds);

        if (imagesError) throw imagesError;

        setImages(images.map(item => item.base64image));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [universityId]);

   // 2. Fetch benefits based on selected category
   useEffect(() => {
    const fetchBenefitsByCategory = async () => {
      if (!selectedCategory) return; // Si no hay categoría seleccionada, no hacer nada
  
      try {
        // Obtener el ID de la categoría a partir del nombre de la categoría seleccionada
        const { data: categoryData, error: categoryError } = await supabase
          .from('category')
          .select('id')
          .eq('name', selectedCategory)
          .single();
  
        if (categoryError) throw categoryError;
        const categoryId = categoryData.id;
  
        // Obtener los IDs de los beneficios asociados a la universidad
        const { data: universityBenefits, error: universityBenefitsError } = await supabase
          .from('universitybenefit')
          .select('benefit_id')
          .eq('university_id', universityId);
  
        if (universityBenefitsError) throw universityBenefitsError;
  
        const benefitIds = universityBenefits.map(b => b.benefit_id);
  
        // Filtrar los beneficios por categoría y por universidad
        const { data: filteredBenefits, error: filteredBenefitsError } = await supabase
          .from('benefit')
          .select('id, name')
          .in('id', benefitIds) // Filtra por los IDs obtenidos
          .eq('category_id', categoryId); // Filtra por categoría
  
        if (filteredBenefitsError) throw filteredBenefitsError;
  
        // Obtener las imágenes asociadas a los beneficios filtrados
        const { data: imageData, error: imageError } = await supabase
          .from('imagebenefit')
          .select('benefit_id, image_id')
          .in('benefit_id', filteredBenefits.map(b => b.id)); // Usar los IDs filtrados
  
        if (imageError) throw imageError;
  
        const imageIds = imageData.map(ib => ib.image_id);
  
        // Obtener los datos de las imágenes
        const { data: imagesData, error: imagesError } = await supabase
          .from('image')
          .select('id, base64image')
          .in('id', imageIds);
  
        if (imagesError) throw imagesError;
  
        // Asignar los beneficios con sus respectivas imágenes
        const benefitsWithImages = filteredBenefits.map(benefit => {
          const benefitImages = imageData
            .filter(ib => ib.benefit_id === benefit.id)
            .map(ib => imagesData.find(img => img.id === ib.image_id).base64image);
  
          return {
            id: benefit.id,
            name: benefit.name,
            images: benefitImages
          };
        });
  
        // Actualizar el estado con los beneficios filtrados
        setBenefits(prevBenefits => ({
          ...prevBenefits,
          [selectedCategory]: benefitsWithImages
        }));
  
      } catch (error) {
        console.error('Error fetching benefits:', error);
      }
    };
  
    fetchBenefitsByCategory();
  }, [selectedCategory, universityId]);
  
  

  // When a category is clicked, update the selected category
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  /*const handleCardClick = (benefitId) => {
    navigate(`/benefit/${benefitId}`); // Redirige a la página del beneficio
  };*/

  const handleCardClick = (benefitId, category) => {
    navigate(`/benefit/${benefitId}?category=${category}&benefitId=${benefitId}`); // Redirige con benefitId y category
  };
  

  return (
    <>
      {images.length > 0 && (
        <Carousel>
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="carousel-image"
                src={`${image}`}
                alt={`Slide ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )}

      <div className="category-carousel-container">
        <div className="category-carousel">
          {categories.map((category, index) => (
            <button
              key={index}
              className="category-button"
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {selectedCategory && (
        <div className="card-container">
          {benefits[selectedCategory] && benefits[selectedCategory].map((benefit, index) => (
            <div key={index} className="benefit-card" onClick={() => handleCardClick(benefit.id, selectedCategory)}>
              <img src={benefit.images[0]} alt={benefit.name} className="benefit-image" />
              <h3>{benefit.name}</h3>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ImageCarousel;





