import React from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 
import './Navbar.css';

const LandingNavBar = () => {
  const navigate = useNavigate(); // Para la navegación

  /*const handleLogin = () => {
    navigate('/Home'); // Redirecciona a la página /Home
  };*/

  const handleLogin1 = () => {
    navigate(`/Home/?university=${"de61ec4a-dc96-46ec-a951-572332f10477"}`); // Redirecciona a la página /Login1
  };

  const handleLogin2 = () => {
    navigate(`/Home/?university=${"d6806308-51fb-466d-89ed-945c06589dd8"}`);
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand href="#">
        <span className="logo-text">StudentHub</span> {/* Usa una clase personalizada para estilos */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="#">Quiénes Somos</Nav.Link>
          <Nav.Link href="#">Contacto</Nav.Link>
        </Nav>
        {/* Agregamos el Dropdown para Login */}
        <Dropdown>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            Log In
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogin1}>Login1</Dropdown.Item> {/* Opción Login1 */}
            <Dropdown.Item onClick={handleLogin2}>Login2</Dropdown.Item> {/* Opción Login2 */}
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );


};

export default LandingNavBar;

