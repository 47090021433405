import React, { useState } from 'react';
import './LandingPage.css';
import LandingNavBar from './LandingNavBar'; // Importa la barra de navegación

const LandingPage = () => {
  const [backgroundImage, setBackgroundImage] = useState('');

  const handleMouseEnter = (image) => {
    setBackgroundImage(image);
  };

  const handleMouseLeave = () => {
    setBackgroundImage('');
  };


  return (
    <div className="landing-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Incluye la barra de navegación */}
      <LandingNavBar />

      <section
        id="benefits"
        className="section benefits"
        onMouseEnter={() => handleMouseEnter('/images/BENEFITS.jpg')}
        onMouseLeave={handleMouseLeave}
      >
        <h2>Beneficios</h2>
        <p className="description">Descubre todos los beneficios que te ofrecemos.</p>
      </section>

      <section
        id="activities"
        className="section activities"
        onMouseEnter={() => handleMouseEnter('/images/cafeteria.jpg')}
        onMouseLeave={handleMouseLeave}
      >
        <h2>Actividades</h2>
        <p className="description">Explora las diversas actividades disponibles.</p>
      </section>

      <section
        id="connect"
        className="section connect"
        onMouseEnter={() => handleMouseEnter('/images/futbol.jpg')}
        onMouseLeave={handleMouseLeave}
      >
        <h2>Conecta con Personas</h2>
        <p className="description">Encuentra y conéctate con personas afines.</p>
      </section>

      <section
        id="accommodations"
        className="section accommodations"
        onMouseEnter={() => handleMouseEnter('/images/GASTRONOMIA.jpg')}
        onMouseLeave={handleMouseLeave}
      >
        <h2>Alojamientos</h2>
        <p className="description">Encuentra el alojamiento perfecto para ti.</p>
      </section>

      <footer className="footer" id="contact">
        <p>© 2024 StudentHub. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default LandingPage;

