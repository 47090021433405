import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { supabase } from '../supabaseClient';

const SearchBar = () => {
  const [query, setQuery] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    let { data, error } = await supabase
      .from('benefits')
      .select('*')
      .ilike('name', `%${query}%`);
    if (error) console.error(error);
    else console.log(data);
  };

  return (
    <Form className="d-flex" onSubmit={handleSearch}>
      <FormControl
        type="search"
        placeholder="Buscar beneficios"
        className="me-2"
        aria-label="Buscar"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button variant="outline-success" type="submit">Buscar</Button>
    </Form>
  );
}

export default SearchBar;
