import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importar useLocation
import { supabase } from '../../../supabaseClient'; // Importar supabase
import NavigationBar from '../../Navbar'; // Importar el NavigationBar
import { Container } from 'react-bootstrap';
import './BenefitDetail.css'; // Importar el archivo CSS para estilos específicos

const BenefitDetail = () => {
  const location = useLocation(); // Hook para obtener el query parameter
  const queryParams = new URLSearchParams(location.search); // Crear un objeto para leer los query parameters
  const benefitId = queryParams.get('benefitId'); // Obtener el ID del beneficio desde los parámetros de la URL
  const categoryName = queryParams.get('category'); // Obtener la categoría del query parameter
  
  const [benefitDetails, setBenefitDetails] = useState([]);

  // Fetch para obtener detalles del beneficio desde la base de datos
  useEffect(() => {
    const fetchBenefitDetails = async () => {
      try {
        // Obtener los detalles del beneficio según el benefitId
        const { data: benefitData, error: benefitError } = await supabase
          .from('benefit')
          .select('name, description')
          .eq('id', benefitId) // Buscar por el ID del beneficio
          .single(); // Solo esperamos un resultado

        if (benefitError) throw benefitError;

        // Actualizamos el estado con los detalles del beneficio
        setBenefitDetails(benefitData);
      } catch (error) {
        console.error('Error fetching benefit details:', error);
      }
    };

    fetchBenefitDetails();
  }, [benefitId]); // El efecto se ejecuta cuando cambia benefitId

  return (
    <div>
      {/* Agregar la barra de navegación */}
      <NavigationBar />

      {/* Container para manejar los márgenes del contenido */}
      <Container className="content-container">
        {/* Subtítulo dinámico basado en la categoría seleccionada */}
        <h2 className="category-subtitle">{categoryName}</h2>
        
        <div style={{ marginBottom: '20px' }}>
              <h3>{benefitDetails.name}</h3>
              <p>{benefitDetails.description}</p>
        </div>  

        {/* Detalles del beneficio y su descripción */}
        {/*{benefitDetails.length > 0 ? (
          benefitDetails.map((benefit, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <h3>{benefit.name}</h3>
              <p>{benefit.description}</p>
            </div>
          ))
        ) : (
          <p>No hay detalles disponibles para este beneficio.</p>
        )}*/}
      </Container>
    </div>
  );
};

export default BenefitDetail;



