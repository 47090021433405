import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/Pages/LandingPage/LandingPage';
import HomePage from './components/Pages/HomePage/HomePage';
import ImageCarousel from './components/Carousel';
import BenefitDetail from './components/Pages/BenefitDetail/BenefitDetail';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/" element={<ImageCarousel />} />
        <Route path="/benefit/:benefitId" element={<BenefitDetail />} /> 
      </Routes>
    </Router>
  );
};

export default App;



