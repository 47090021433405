import React from 'react';
import NavigationBar from '../../Navbar';
import ImageCarousel from '../..//Carousel';
import SearchBar from '../../SearchBar';
import { Container } from 'react-bootstrap';
import './HomePage.css';  // Asegúrate de linkear App.css


const HomePage = () => {
    return (  
        <div>
      <NavigationBar />
      <Container className="search-bar-spacing"> {/* Clase agregada para el espaciado */}
        <SearchBar />
        <div className="carousel-spacing"> {/* Clase agregada para el espaciado del carrusel */}
          <ImageCarousel />
        </div>
      </Container>
    </div>
    );
}
 
export default HomePage;