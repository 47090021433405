import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Importar Link desde react-router-dom
import './Navbar.css'; // Asegúrate de crear y linkear el archivo CSS para estilos personalizados.

const NavigationBar = () => {
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand>
        {/* Envolver el logo en un Link para que redirija a la HomePage */}
        <Link to="/Home" className="navbar-logo">
          <span className="logo-text">StudentHub</span> {/* Usa una clase personalizada para estilos */}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="#">Somos StudentHub</Nav.Link>
          <Nav.Link href="#">Mis Chats</Nav.Link>
          <Nav.Link href="#">Contactar Personas</Nav.Link>
          <Nav.Link href="#">Hospedajes</Nav.Link>
          <Nav.Link href="#">Actividades</Nav.Link>
        </Nav>
        <Button variant="outline-primary" href="#">
          <i className="fas fa-user"></i> {/* Ícono de persona */}
          Perfil
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;


